@font-face {
  font-family: 'Futura LT Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Futura LT Regular'), url('../assets/fonts/FuturaLT.woff') format('woff');
}
  
@font-face {
  font-family: 'Futura LT Book';
  font-style: normal;
  font-weight: normal;
  src: local('Futura LT Book'), url('../assets/fonts/FuturaLT-Book.woff') format('woff');
}

@font-face {
  font-family: 'Futura LT Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Futura LT Medium'), url('../assets/fonts/FuturaLT-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Futura LT Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Futura LT Bold'), url('../assets/fonts/FuturaLT-Bold.woff') format('woff');
}

* { margin:0; padding:0; } 
html, body { width:100%; height:100%;} 

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: inherit;
}
ul,li {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
 }

 .disabler {
  height: 100%;
  width: 100%; }

 .gallery-item .disabler {
  position: absolute; 
}

:focus {outline:none;}


/* Assuming the main header bar has a class like this, found via your browser's developer tools */
.ril__header {
  background-color: white !important; /* White background */
  color: black !important; /* Black text for visibility */
}

/* If the icons are not visible against the new background, ensure they are styled appropriately */
.ril__toolbarItem, .ril__toolbarItemChild {
  color: black !important; /* Black icons */
}